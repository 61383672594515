import axios from "axios";

import { authLogoutSuccess, setRedirectFalse } from "../store/actions/auth";
import { sessionExpiredError } from "../store/actions/error";
import { getAlias } from "../utils/utils";
import store from "../store/index";

const alias = getAlias();

const axiosRefresh = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	transformRequest: [
		function (data, headers) {
			headers["Authorization"] = "Bearer " + localStorage.getItem("token");
			headers["X-Custom-Header"] = JSON.stringify({
				alias: alias,
				lang: localStorage.getItem("i18nextLng"),
			});
			headers["Content-Type"] = "multipart/form-data";
		},
	],
});

const customAxios = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	transformRequest: [
		function (data, headers) {
			const formData = new FormData();
			if (data instanceof FormData) {
			} else {
				if (data) {
					for (var pr in data) {
						formData.set(pr, data[pr]);
					}
				}
			}

			headers["Authorization"] = "Bearer " + localStorage.getItem("token");
			headers["X-Custom-Header"] = JSON.stringify({
				alias: alias,
				lang: localStorage.getItem("i18nextLng"),
			});
			headers["Content-Type"] = "multipart/form-data";
			if (data instanceof FormData) {
				return data;
			} else {
				return formData;
			}
		},
	],
});

//customAxios.defaults.withCredentials = false;

customAxios.interceptors.response.use(
	function (response) {
		// If the request succeeds, we don't have to do anything and just return the response
		//console.log('RESPONSE: ', response);

		return response;
	},
	function (error) {
		const errorResponse = error.response;

		if (isTokenExpiredError(errorResponse)) {
			return resetTokenAndReattemptRequest(error);
		}
		// If the error is due to other reasons, we just throw it back to axios

		return Promise.reject(error);
	}
);

function isTokenExpiredError(errorResponse) {
	// Your own logic to determine if the error is due to JWT token expired returns a boolean value
	//console.log('errorResponse: ', errorResponse);
	// GESTIRE 404 tipo se non c'è l'alias
	if (errorResponse.status === 401) {
		return true;
	} else {
		return false;
	}
}

let isAlreadyFetchingAccessToken = false;

// This is the list of waiting requests that will retry after the JWT refresh complete
let subscribers = [];

async function resetTokenAndReattemptRequest(error) {
	try {
		const { response: errorResponse } = error;
		// const resetToken = await TokenUtils.getResetToken(); // Your own mechanism to get the refresh token to refresh the JWT token
		//   if (!resetToken) {
		//     // We can't refresh, throw the error anyway
		//     return Promise.reject(error);
		//   }
		/* Proceed to the token refresh procedure
      We create a new Promise that will retry the request,
      clone all the request configuration from the failed
      request in the error object. */

		const retryOriginalRequest = new Promise((resolve) => {
			/* We need to add the request retry to the queue
      since there another request that already attempt to
      refresh the token */
			addSubscriber((access_token) => {
				errorResponse.config.headers.Authorization =
					"Bearer " + localStorage.getItem("token");
				resolve(axios(errorResponse.config));
			});
		});
		if (!isAlreadyFetchingAccessToken) {
			isAlreadyFetchingAccessToken = true;
			const response = await axiosRefresh({
				method: "get",
				url: "auth/refresh",
				//   data: {
				//     token: resetToken // Just an example, your case may vary
				//   }
				headers: {
					Authorization: "Bearer " + localStorage.getItem("token"),
				},
			});
			if (!response.data) {
				return Promise.reject(error);
			}
			const newToken = response.data.token;
			//TokenUtils.saveRefreshToken(newToken); // save the newly refreshed token for other requests to use
			localStorage.setItem("token", newToken);
			isAlreadyFetchingAccessToken = false;
			onAccessTokenFetched(newToken);
		}
		return retryOriginalRequest;
	} catch (err) {
		//alert();
		// Attenzione qui ci potrebbe entrare anche se non deve fare il logout
		localStorage.removeItem("token");
		store.dispatch(setRedirectFalse());
		store.dispatch(authLogoutSuccess());
		if (err.response.status === 401) {
			console.log("expired token");
			store.dispatch(sessionExpiredError());
		}
		return Promise.reject(err);
	}
}

function onAccessTokenFetched(access_token) {
	// When the refresh is successful, we start retrying the requests one by one and empty the queue
	subscribers.forEach((callback) => callback(access_token));
	subscribers = [];
}

function addSubscriber(callback) {
	subscribers.push(callback);
}

export default customAxios;
