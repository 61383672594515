import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Flex, Col, Box, Text } from '../../components/Ui';
import Fade from '../../components/Fade/Fade';
import AdvantageBox from '../../components/AdvantageBox/AdvantageBox';

const Advantages = () => {
  const advantages_cms = useSelector(state => state.cms.advantages);
  const partners = useSelector(state => state.partners.partnerList);
  const user = useSelector(state => state.user.user.userinfo);

  const advantages_text = advantages_cms[0].contents.find(e => e.name === 'advantages_text');
  const advantages_image = advantages_cms[0].contents.find(e => e.name === 'advantages_image');

  return (
    <Fade>
      <Flex row={15}>
        <Col width={25} className='md_mb-30'>
          <Box>
            <Text primary size={34} className='mb-20'>
              {advantages_text?.i18l.title}
            </Text>
            <Text as='div'>{advantages_text?.i18l.content}</Text>
            <AdvantagesLeftImage>
              <img src={advantages_image?.img} alt='' />
              <Text white light size={70}>
                {user?.wallet_amount || '0'}
              </Text>
              <Text white light size={34} style={{ top: 13 }}>
                €
              </Text>
            </AdvantagesLeftImage>
          </Box>
        </Col>
        <Col width={75}>
          <Flex wrap='wrap' row={15}>
            {partners?.map((partner, partnerIndex) => (
              <Col width={33.333} key={partnerIndex}>
                <AdvantageBox partner={partner} />
              </Col>
            ))}
          </Flex>
        </Col>
      </Flex>
    </Fade>
  );
};

const AdvantagesLeftImage = styled.div`
  margin: 20px -15px -30px -15px;
  height: 155px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  span {
    position: relative;
  }
`;

export default Advantages;
