import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as actionCreators from "../../store/actions";
import { Loader, Section, CarouselViewer, Viewer } from "../../components/Ui";
import LoaderViewerContainer from "../../components/Ui/Viewer/LoaderViewerContainer";
import BoxContent from "../../components/BoxContent";
import ErrorReload from "../../components/ErrorReload";
import Seo from "../../components/Seo";
import { withRouter } from "react-router-dom";
import NotFound from "../NotFound";

const Home = (props) => {
	const [tagName, setTagName] = useState(null);
	const [isValid, setIsValid] = useState(true);

	const {
		viewerLoading,
		viewer,
		getViewer,
		viewerError,
		getAllPromo,
		getHgPromo,
		loadinghgpromo,
		loadingallpromo,
		hgpromo,
		allpromos,
		errorhgpromo,
		errorallpromo,
		appConfig: {
			i18l,
			design: {
				template_props: {
					highlights,
					allpromo,
					highlights_columns,
					allpromo_columns,
					tags_columns,
				},
			},
		},
		location: { pathname },
		// tag
		getPromoTag,
		tagsclient,
		loadingclienttags,
		errorclienttags,
		getPromoTagViewer,
		tagviewer,
		loadingtagviewer,
		errortagviewer,
		// error
		error404,
		reset404error,
	} = props;

	const highl = {
		loading: loadinghgpromo,
		promos: hgpromo,
		perrow: highlights_columns,
		scream: i18l.highlights.title ? i18l.highlights.title : null,
		description: i18l.highlights.content ? i18l.highlights.content : null,
		error: errorhgpromo,
		clicked: getHgPromo,
	};

	const all = {
		loading: loadingallpromo,
		promos: allpromos,
		perrow: allpromo_columns,
		scream: i18l.allpromo ? i18l.allpromo.title : null,
		description: i18l.allpromo ? i18l.allpromo.content : null,
		error: errorallpromo,
		clicked: getAllPromo,
	};

	const tagClient = {
		loading: loadingclienttags,
		promos: tagsclient,
		perrow: tags_columns,
		scream: i18l.allpromo ? i18l.allpromo.title : null,
		description: i18l.allpromo ? i18l.allpromo.content : null,
		error: errorclienttags,
		clicked: getPromoTag,
	};

	useEffect(() => {
		getViewer();
		getHgPromo();
		getAllPromo();
		localStorage.setItem("lasturl", "");
	}, []);

	useEffect(() => {
		const path = pathname.split("/")[1];
		const tag = pathname.split("/")[2];
		setTagName(tag);
	}, [pathname]);

	useEffect(() => {
		reset404error();
		if (tagName) {
			getPromoTagViewer(tagName);
			getPromoTag(tagName, "");
		}
		window.scrollTo(0, 0);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tagName]);

	useEffect(() => {
		if (error404.length >= 1) {
			setIsValid(false);
		} else {
			setIsValid(true);
		}
	}, [error404]);

	let content = null;
	let contentHigh = null;
	let contentAll = null;
	let contentTag = null;
	let contentViewer = null;
	let contentTagViewer = null;
	let seo = null;


	if (Object.entries(i18l).length !== 0) {
		const {
			seo: { title, description, keywords },
		} = i18l;
		seo = <Seo title={title} description={description} keywords={keywords} image={viewer[0] ? viewer[0].img_mobile : ''} />;
	}

	contentViewer = (
		<Section marginBottomSmall>
			{viewerLoading ? (
				<LoaderViewerContainer>
					{<Loader show={viewerLoading} />}
				</LoaderViewerContainer>
			) : viewerError >= 500 ? (
				<LoaderViewerContainer>
					<ErrorReload clicked={getViewer} />
				</LoaderViewerContainer>
			) : (
				<CarouselViewer elements={viewer} />
			)}
		</Section>
	);
	contentTagViewer = (
		<Section marginBottomSmall>
			{loadingtagviewer ? (
				<LoaderViewerContainer>
					{<Loader show={loadingtagviewer} />}
				</LoaderViewerContainer>
			) : errortagviewer >= 500 ? (
				<LoaderViewerContainer>
					<ErrorReload clicked={getPromoTagViewer} />
				</LoaderViewerContainer>
			) : (
				<Viewer
					logo={tagviewer.logo}
					imgdesktop={tagviewer.img_desktop}
					imgmobile={tagviewer.img_mobile}
					dark_text={tagviewer.dark_text}
					ctatype={tagviewer.calltoaction_type}
					scream={tagviewer.i18l.title}
					description={tagviewer.i18l.content}
					cta={tagviewer.i18l.calltoaction}
					url={tagviewer.i18l.url}
				/>
			)}
		</Section>
	);
	contentHigh = highlights ? <BoxContent type="highlights" {...highl} /> : null;
	contentAll = allpromo ? <BoxContent type="allpromos" {...all} /> : null;
	contentTag = <BoxContent type="tags" {...tagClient} istag={true} />;

	if (tagName) {
		content = (
			<React.Fragment>
				{seo}
				{contentTagViewer}
				{contentTag}
			</React.Fragment>
		);
	} else {
		content = (
			<React.Fragment>
				{seo}
				{contentViewer}
				{contentHigh}
				{contentAll}
			</React.Fragment>
		);
	}

	return (
		<React.Fragment>
			{isValid ? (
				content
			) : (
				<div style={{ minHeight: "100vh" }}>
					<NotFound internal />
				</div>
			)}
		</React.Fragment>
	);
};

const mapStateToProps = (state) => {
	return {
		islogged: state.auth.token,
		viewerLoading: state.homeViewer.loading,
		viewer: state.homeViewer.viewer,
		viewerError: state.homeViewer.error,
		hgpromo: state.promo.hgpromo,
		errorhgpromo: state.promo.errorhgpromo,
		errorallpromo: state.promo.errorallpromo,
		allpromos: state.promo.allpromo,
		loadinghgpromo: state.promo.loadinghgpromo,
		loadingallpromo: state.promo.loadingallpromo,
		appConfig: state.app.config,
		tagsclient: state.promo.tagsclient,
		loadingclienttags: state.promo.loadingclienttags,
		errorclienttags: state.promo.errorclienttags,
		tagviewer: state.promo.tagviewer,
		loadingtagviewer: state.promo.loadingtagviewer,
		errortagviewer: state.promo.errortagviewer,
		error404: state.promo.error404,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getViewer: () => dispatch(actionCreators.getViewer()),
		getAllPromo: () => dispatch(actionCreators.getAllPromo()),
		getHgPromo: () => dispatch(actionCreators.getHighlightsPromo()),
		getPromoTag: (slugtag, cid) =>
			dispatch(actionCreators.getPromoTag(slugtag, cid)),
		getPromoTagViewer: (slug) =>
			dispatch(actionCreators.getPromoTagViewer(slug)),
		reset404error: () => dispatch(actionCreators.reset404error()),
	};
};

Home.propTypes = {
	viewerLoading: PropTypes.bool,
	viewer: PropTypes.any,
	getViewer: PropTypes.func,
	getAllPromo: PropTypes.func,
	getHgPromo: PropTypes.func,
	loadinghgpromo: PropTypes.bool,
	loadingallpromo: PropTypes.bool,
	loadingclienttags: PropTypes.bool,
	hgpromo: PropTypes.array,
	allpromos: PropTypes.array,
	tagsclient: PropTypes.array,
	appConfig: PropTypes.object,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));
