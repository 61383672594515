import styled from 'styled-components';

const Select = styled.select`
  display: block;
  font-size: 14px;
  font-family: sans-serif;
  font-weight: 700;
  color: #444;
  padding: 0 15px;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  outline: 0;
  height: 50px;
  border-radius: 10px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  color: ${props => props.theme.primary};
  background-color: ${props => props.theme.input.bg};
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='34' viewBox='0 0 34 34' width='34' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-position: right 0 top 14px, 0 0;
  background-repeat: no-repeat;
  background-size: 30px auto, 100%;
  border: 2px solid
    ${props =>
      (!props.isvalid && props.istouched && props.firstSubmit) ||
      (!props.isvalid && props.firstSubmit)
        ? props.theme.input.error
        : props.theme.input.bg};

  &::placeholder {
    color: #47545d59;
  }
`;

export default Select;
