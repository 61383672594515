export const theme = {
  primary: '#01cdff',
  secondary: '#cccccc',
  default_color: '#292e32',
  default_colortext: '#fff',
  modal: '#f7f7f7',
  intialColorLoader: '#000',
  bgboard: '#f4f4f4',
  bgcode: '#f4f4f4',
  borderCode: '#d0cfcf',
  btnRound: true,
  btnTextColor: '#fff',
  footer_desktop_height: 140,
  footer_mobile_height: 221,
  footer_logo_size: 200,
  header_desktop_height: 93,
  header_mobile_height: 86,
  text_primary: '#000000',
  fontSource: 'https://fonts.googleapis.com/css?family=Roboto:100,300,400,900&display=swap',
  fontFamily: 'Roboto',
  text: {
    primary: '#4a4a4a',
    secondary: '#fff',
    tertiary: '#cccccc',
  },
  input: {
    bg: '#EAF0F3',
    label: '#fff',
    labelModal: '#333333',
    error: '#ff0000',
  },
  isrect: false,
  textStyles: {
    vscream: {
      tag: 'h1',
      fontSize: ['32px', '42px', '52px'],
      fontWeight: 100,
      lineHeight: ['36px', '50px', '60px'],
    },
    vbody: {
      tag: 'p',
      fontSize: ['22px', '22px', '22px'],
      fontWeight: 100,
      lineHeight: ['28px', '28px', '28px'],
    },
    scream: {
      tag: 'p',
      fontSize: ['32px', '40px', '48px'],
      fontWeight: 100,
      lineHeight: ['36px', '48px', '56px'],
    },
    body: {
      tag: 'p',
      fontSize: ['22px', '22px', '26px'],
      fontWeight: 100,
      lineHeight: ['26px', '26px', '30px'],
    },
    bscream: {
      tag: 'p',
      fontSize: ['24px', '26px', '30px'],
      fontWeight: 300,
      lineHeight: ['28px', '30px', '34px'],
    },
    bscreamsmall: {
      tag: 'p',
      fontSize: ['24px', '24px', '24px'],
      fontWeight: 300,
      lineHeight: ['28px', '28px', '28px'],
    },
    bbody: {
      tag: 'p',
      fontSize: ['20px', '18px', '18px'],
      fontWeight: 100,
      lineHeight: ['24px', '24px', '24px'],
    },
    label: {
      tag: 'p',
      fontSize: ['15px', '18px', '18px'],
      fontWeight: 100,
      lineHeight: ['18px', '24px', '24px'],
    },
    middlescream: {
      tag: 'p',
      fontSize: ['1.5rem', '2rem'],
      fontWeight: 100,
      lineHeight: ['1.75rem', '2.25rem'],
    },
    title: {
      tag: 'p',
      fontSize: ['24px', '30px', '36px'],
      fontWeight: 100,
      lineHeight: ['28px', '34px', '40px'],
    },
    text: {
      tag: 'p',
      fontSize: ['16px', '16px', '18px'],
      fontWeight: 100,
      lineHeight: ['24px', '26px', '28px'],
    },
    smallLink: {
      tag: 'p',
      fontSize: ['14px', '14px', '14px'],
      textDecoration: 'underline',
      fontWeight: 100,
      lineHeight: ['14px', '14px', '14px'],
    },
    underline: {
      textDecoration: 'underline',
    },
    infolabel: {
      tag: 'p',
      fontSize: ['12px', '16px', '16px'],
      lineHeight: ['16px', '20px', '20px'],
      fontWeight: 100,
    },
    middlesc: {
      tag: 'p',
      fontSize: ['26px', '42px', '42px'],
      lineHeight: ['30px', '46px', '46px'],
      fontWeight: 300,
    },
    middlep: {
      tag: 'p',
      fontSize: ['18px', '22px', '22px'],
      lineHeight: ['22px', '26px', '26px'],
      fontWeight: 400,
    },
    titleGame: {
      tag: 'p',
      fontSize: ['36px', '46px', '56px'],
      fontWeight: 100,
      lineHeight: ['40px', '50px', '60px'],
    },
    descGame: {
      tag: 'p',
      fontSize: ['18px', '21px', '24px'],
      fontWeight: 100,
      lineHeight: ['22px', '24px', '28px'],
    },
    question: {
      tag: 'p',
      fontSize: ['18px', '20px', '20px'],
      fontWeight: 400,
      lineHeight: ['21px', '24px', '24px'],
    },
    answer: {
      tag: 'p',
      fontSize: ['18px', '18px', '18px'],
      fontWeight: 300,
      lineHeight: ['21px', '22px', '22px'],
    },
    navLink: {
      tag: 'p',
      fontSize: ['16px', '16px', '16px'],
      fontWeight: 300,
      lineHeight: ['20px', '20px', '20px'],
    },
    navLinkSmall: {
      tag: 'p',
      fontSize: ['12px', '12px', '12px'],
      fontWeight: 300,
      lineHeight: ['16px', '16px', '16px'],
    },
  },
  fontSizes: [11, 13, 14, 15, 16, 18, 20, 21, 22, 24, 26, 28, 32, 36, 52],
  breakpoints: ['48em', '64em', '48em'],
  awesomegrid: {
    mediaQuery: 'only screen',
    columns: {
      xs: 4,
      sm: 12,
      md: 12,
      lg: 12,
      xl: 12,
    },
    gutterWidth: {
      xs: 1,
      sm: 1,
      md: 1.5,
      lg: 1.5,
      xl: 1.5,
    },
    paddingWidth: {
      xs: 1,
      sm: 1,
      md: 1.5,
      lg: 1.5,
      xl: 1.5,
    },
    container: {
      xs: 'full', // 'full' = max-width: 100%
      sm: 'full', // 'full' = max-width: 100%
      md: 'full', // 'full' = max-width: 100%
      lg: 90, // max-width: 1440px
      xl: 90, // max-width: 1440px
    },
    breakpoints: {
      xs: 1,
      sm: 48, // 768px
      md: 64, // 1024px
      lg: 90, // 1440px
      xl: 120, // 1920px
    },
  },
};

export const configTheme = appConfig => {
  if (appConfig !== undefined) {
    if (appConfig.design !== undefined) {
      if (appConfig.design.template_props !== undefined) {
        const {
          color_primary,
          color_secondary,
          isrect,
          button_textcolor,
          button_round,
          footer_desktop_height,
          footer_mobile_height,
          header_desktop_height,
          header_mobile_height,
          text_color_primary,
          font_source,
          font_family,
          logo_size,
          footer_logo_size,
          input_bg,
        } = appConfig.design.template_props;
        if (color_primary) {
          theme.primary = `${color_primary}`;
        }
        if (color_secondary) {
          theme.secondary = `${color_secondary}`;
        }
        if (text_color_primary) {
          theme.text_primary = `${text_color_primary}`;
        }
        if (isrect) {
          theme.isrect = isrect;
        }
        if (typeof button_round !== 'undefined') {
          theme.btnRound = button_round;
        }
        if (button_textcolor) {
          theme.btnTextColor = button_textcolor;
        }
        if (footer_desktop_height) {
          theme.footer_desktop_height = footer_desktop_height;
        }
        if (footer_mobile_height) {
          theme.footer_mobile_height = footer_mobile_height;
        }
        if (header_desktop_height) {
          theme.header_desktop_height = header_desktop_height;
        }
        if (header_mobile_height) {
          theme.header_mobile_height = header_mobile_height;
        }
        if (font_source) {
          theme.fontSource = `${font_source}`;
        }
        if (font_family) {
          theme.fontFamily = `${font_family}`;
        }
        if (logo_size) {
          theme.logo_size = `${logo_size}`;
        }
        if (footer_logo_size) {
          theme.footer_logo_size = footer_logo_size;
        }
        if (input_bg) {
          theme.input.bg = input_bg;
        }
      }
    }
  }
};
