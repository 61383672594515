import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Wrapper, Logo } from '../Ui';
import NavigationItems from '../Navigation/NavigationItems/NavigationItems';
import MenuLateral from '../MenuLateral';
import { respondTo } from '../../theme/mixin';

const HeaderSection = styled.header`
  border-top: 5px solid ${props => props.theme.primary};
  background-color: #fff;
  box-shadow: 0px 5px 15px #00000026;
  border-radius: 0 0 10px 10px;
  position: fixed;
  top: 0;
  left: 15px;
  z-index: 9;
  width: calc(100% - 30px);

  ${respondTo.sm`
    margin-left: 0;
    width: 100%;
    position: static;
    margin-bottom: 30px;
  `}
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: ${props => props.theme.header_mobile_height + 'px'};

  ${respondTo.sm`
    min-height: ${props => props.theme.header_desktop_height + 'px'};
  `}
`;

const LogoNavContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
`;

const NavContainer = styled.div`
  display: ${props => (props.isOpen ? 'block' : 'none')};
  position: absolute;
  top: 83px;
  left: 0;
  width: 100%;
  background-color: #fff;
  border-top: 1px solid #dce4e8;
  box-shadow: 0px 5px 15px #00000026;
  border-radius: 0 0 10px 10px;

  ${respondTo.sm`
		display: flex;
    top: 0;
    position: static;
    border-top: none;
    box-shadow: none;
	`}
`;

const Header = props => {
  const { logo, logout, isLogged, multipromo, i18l } = props;

  const games = useSelector(state => state.game.games);

  const [idGame, setIdGame] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    if (games.length === 1) {
      setIdGame(games[0].id);
    }
  }, [games]);

  return (
    <HeaderSection>
      <Wrapper>
        <HeaderContainer>
          <LogoNavContainer>
            <Logo logo={logo} />
            <NavContainer isOpen={isMenuOpen}>
              <NavigationItems
                isLogged={isLogged}
                multipromo={multipromo}
                i18l={i18l}
                idGame={idGame}
                logout={logout}
              />
            </NavContainer>
          </LogoNavContainer>
          <MenuLateral
            {...props}
            toggleMenu={() => setIsMenuOpen(!isMenuOpen)}
            isMenuOpen={isMenuOpen}
          />
        </HeaderContainer>
      </Wrapper>
    </HeaderSection>
  );
};

Header.propTypes = {
  logo: PropTypes.string,
  appInit: PropTypes.func,
  logout: PropTypes.func,
  isLogged: PropTypes.string,
  appConfig: PropTypes.object,
};

export default Header;
