import React, { useState, useEffect } from 'react';
import { setCookie, getCookie } from '../../utils/utils';
import { Button } from '../Ui';
import Fade from '../Fade';
import styled from 'styled-components';
import { respondTo } from '../../theme/mixin';

const Container = styled.div`
  position: fixed;
  bottom: 15px;
  z-index: 9999999;
  width: 100%;
`;

const Banner = styled.div`
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  margin: auto;
  max-width: 1200px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
  display: block;
  align-items: center;

  ${respondTo.md`
		display: flex;
	`}
`;

const Text = styled.p`
  font-size: 14px;
  line-height: 20px;

  a {
    color: ${props => props.theme.primary};
    padding-left: 5px;
  }
`;

const GDPR = props => {
  const {
    content: {
      gdpr: { content, extra },
    },
  } = props;

  const [showBanner, setShowBanner] = useState(false);

  const setCookieHandler = () => {
    setCookie('gdpr', true, 30);
    setShowBanner(false);
  };

  useEffect(() => {
    setTimeout(() => {
      if (!getCookie('gdpr')) {
        setShowBanner(true);
      }
    }, 1000);
  }, []);

  return showBanner ? (
    <Container>
      <Fade show={showBanner}>
        <Banner>
          <Text>
            {content}
            <a
              href={process.env.PUBLIC_URL + '/pdf/cookie_policy.pdf'}
              target='_blank'
              rel='noopener noreferrer'
            >
              Cookie Policy
            </a>
          </Text>
          <Button onClick={setCookieHandler}>{extra}</Button>
        </Banner>
      </Fade>
    </Container>
  ) : null;
};

export default GDPR;
