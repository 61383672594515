import React from "react";
import CountdownEl from "react-countdown";
import styled from "styled-components";
import { RightArrow } from "styled-icons/boxicons-solid";
import { darken } from "polished";

const CountDownContainer = styled.div`
	height: 50px;
	width: ${(props) => (props.isTime ? "50px" : "110px")};
	border-radius: ${(props) => (props.isTime ? "50%" : "50px")};
	background-color: ${(props) => props.theme.secondary};
	&:hover {
		background-color: ${(props) => darken(0.1, props.theme.secondary)};
		border-radius: ${(props) => (props.isTime ? "50%" : "50px")};
	}
`;

const NextContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 50px;
	font-size: 22px;
	color: white;
	text-align: center;
	cursor: pointer;
	& span {
		display: block;
		font-size: 14px;
		margin-top: -8px;
	}
	& svg {
		margin-left: 3px;
	}
	&:hover {
		background-color: ${(props) => darken(0.1, props.theme.secondary)};
		border-radius: 50%;
	}
`;

const CountDown = (props) => {
	const { clicked, start, time, gotonext, rend, timeComplete } = props;

	console.log("props ", props);

	let nextDiv = null;

	if (timeComplete) {
		nextDiv = (
			<NextContainer onClick={gotonext} disabled={true}></NextContainer>
		);
	}
	if (start) {
		nextDiv = (
			<NextContainer>
				<CountdownEl date={Date.now() + time} renderer={rend} />
			</NextContainer>
		);
	} else {
		nextDiv = (
			<NextContainer onClick={gotonext} disabled={true}>
				Avanti
				<RightArrow size={28} />
			</NextContainer>
		);
	}

	return (
		<React.Fragment>
			<CountDownContainer isTime={start}>{nextDiv}</CountDownContainer>
		</React.Fragment>
	);
};

export default CountDown;
