import React from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 1px;
`;

const CheckboxContainer = styled.div`
  width: 100%;
  position: relative;

  label {
    display: flex;
    align-items: center;
    cursor: pointer;

    &:before {
      content: '';
      flex: 0 0 24px;
      height: 24px;
      margin-right: 10px;
      display: inline-block;
      border-radius: 7px;
      border: 2px solid
        ${props =>
          (!props.isvalid && props.istouched && props.firstSubmit) ||
          (!props.isvalid && props.firstSubmit)
            ? props.theme.input.error
            : props.value === 1
            ? props.theme.primary
            : props.theme.input.bg};
      background-color: ${props =>
        props.value === 1 ? props.theme.primary : props.theme.input.bg};
      transition: all 150ms;
    }

    a {
      color: ${props => props.theme.primary};
      font-weight: 700;
      text-decoration: underline;
    }
  }
`;

const Checkbox = props => {
  return (
    <CheckboxContainer
      isvalid={props.isvalid}
      istouched={props.istouched}
      firstSubmit={props.firstSubmit}
      value={parseInt(props.value)}
    >
      <HiddenCheckbox {...props} />
      <label htmlFor={props.name}>{parse(props.label)}</label>
    </CheckboxContainer>
  );
};

export default Checkbox;
