import React from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import { createBrowserHistory } from 'history';
import { LastLocationProvider } from 'react-router-last-location';

import AuthLayout from '../layouts/AuthLayout';
import BaseLayout from '../layouts/BaseLayout';

import Home from '../views/Home';
import NotFound from '../views/NotFound';
import Advantages from '../views/Advantages/Advantages';
import SingleAdvantage from '../views/SingleAdvantage/SingleAdvantage';

const Register = React.lazy(() => import('../views/Register'));
const Game = React.lazy(() => import('../views/Game'));
const Initiative = React.lazy(() => import('../views/Initiative'));
const Login = React.lazy(() => import('../views/Login'));
const RecoveryPassword = React.lazy(() => import('../views/RecoveryPassword'));
const ResetPassword = React.lazy(() => import('../views/ResetPassword'));
const Profile = React.lazy(() => import('../views/Profile'));
const UserActivated = React.lazy(() => import('../views/UserActivated'));
const Oauth = React.lazy(() => import('../views/Oauth'));
const Panel = React.lazy(() => import('../views/Panel'));
const Awards = React.lazy(() => import('../views/Awards'));
const Contact = React.lazy(() => import('../views/Contact/Contact'));

const history = createBrowserHistory();

const PrivateRoute = ({ component: Component, layoutCmp: Layout, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        rest.isLogged !== null ? (
          <Layout {...props} {...rest} cmp={Component} />
        ) : (
          <Redirect
            to={{
              pathname: !rest.ownLogin ? '/' : '/auth/login',
              state: {
                from: rest.location.pathname,
                isLogged: rest.isLogged,
              },
            }}
          />
        )
      }
    />
  );
};

const Routes = props => {
  const { ownLogin, multipromo } = props;

  let ownRoutes = null;

  if (ownLogin) {
    ownRoutes = (
      <React.Fragment>
        <Route path='/auth/login' exact render={route => <AuthLayout cmp={Login} {...route} />} />
        <Route
          path='/auth/recovery-password'
          exact
          render={route => <AuthLayout cmp={RecoveryPassword} {...route} />}
        />
        <Route
          path='/auth/register'
          exact
          render={route => <AuthLayout cmp={Register} {...route} />}
        />
        <Route
          path='/activate/:id'
          exact
          render={route => <AuthLayout cmp={UserActivated} {...route} />}
        />
        <Route
          path='/reset-password/:onetimetoken'
          exact
          render={route => <AuthLayout cmp={ResetPassword} {...route} />}
        />
      </React.Fragment>
    );
  }

  return (
    <Router history={history}>
      <LastLocationProvider>
        <Switch>
          <Route
            path='/promo/:initiative'
            exact
            render={route => <BaseLayout cmp={Initiative} {...route} />}
          />
          <Route path='/oauth/:connect' exact render={route => <Oauth {...route} />} />
          <Route path='/tags/:tag' exact render={route => <BaseLayout cmp={Home} {...route} />} />
          <Route path='/panel' exact component={Panel} />
          <PrivateRoute
            path='/'
            exact
            component={multipromo ? Home : Initiative}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path='/promo/:initiative/game/:id'
            exact
            component={Game}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
            type='gamelayout'
          />
          <PrivateRoute
            path='/promo/game/:id'
            exact
            component={Game}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
            type='gamelayout'
          />
          <PrivateRoute
            path='/advantages'
            exact
            component={Advantages}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path='/advantage/:id'
            exact
            component={SingleAdvantage}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path='/user/profile'
            exact
            component={Profile}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path='/contact'
            exact
            component={Contact}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path='/user/awards'
            exact
            component={Awards}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          {ownRoutes}
          <Route component={NotFound} />
        </Switch>
      </LastLocationProvider>
    </Router>
  );
};

const mapStateToProps = state => {
  return {
    isLogged: state.auth.token,
    ownLogin: state.app.config.ownlogin.active,
    multipromo: state.app.config.settings.multipromo,
  };
};

export default connect(mapStateToProps)(Routes);
