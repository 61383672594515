import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper } from '../Ui';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { KeyboardArrowUp } from '@styled-icons/material';
import parse from 'html-react-parser';

const FooterContainer = styled.footer`
  margin-top: 30px;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
  box-shadow: 0px -5px 15px #00000026;
`;

const TopBar = styled.div`
  background-color: #fff;
  padding: 10px 0;
  text-align: center;

  img {
    max-width: ${props => props.theme.footer_logo_size}px;
  }
`;

const BottomBar = styled.div`
  background-color: #dce4e8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;

  img {
    max-width: 20px;
    height: auto;
  }

  .text {
    display: flex;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 700;

    p {
      padding-right: 5px;
    }

    a {
      padding: 0 5px;
    }
  }
`;

const IconUp = styled(KeyboardArrowUp)`
  cursor: pointer;
  color: ${props => props.theme.primary};
  flex: 0 0 30px;
`;

const Footer = props => {
  const { footercontent } = props;

  // Get info from CMS section
  const footerlogo = footercontent[0].contents.find(el => el.name === 'logo');
  const footertext = footercontent[0].contents.find(el => el.name === 'text');

  return (
    <Wrapper>
      <FooterContainer>
        <TopBar>
          <img src={footerlogo?.img} alt='' />
        </TopBar>
        <BottomBar>
          <div />
          <div className='text'>
            {parse(footertext?.i18l.content)} |{' '}
            <a href={process.env.PUBLIC_URL + '/pdf/cookie_policy.pdf'} target='blank'>
              Cookie Policy
            </a>{' '}
            |{' '}
            <a href={process.env.PUBLIC_URL + '/pdf/privacy_policy.pdf'} target='blank'>
              Privacy Policy
            </a>
          </div>
          <IconUp size={30} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} />
        </BottomBar>
      </FooterContainer>
    </Wrapper>
  );
};

Footer.defaultProps = {
  content: '',
};

Footer.propTypes = {
  footercontent: PropTypes.array,
};

export default Footer;
