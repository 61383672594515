import React from 'react';
import { Typography } from '../Ui';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { respondTo } from '../../theme/mixin';

const ScreamContainer = styled.div`
    margin-bottom: ${props => props.description ? '8px' : '24px' };
    ${respondTo.sm`
        margin-bottom: ${props => props.description ? '12px' : '40px' };
    `}
    ${respondTo.md`
        margin-bottom: ${props => props.description ? '16px' : '48px' };
    `}
`;

const BodyContainer = styled.div`
    margin-bottom: 20px;
    display: ${props => props.description ? 'block' : 'none'};
    ${respondTo.sm`
        margin-bottom: 36px;
    `}
`;


const TitleSection = (props) => {

    return (
        <React.Fragment>
            <ScreamContainer {...props} >
                <Typography as="h3" type="scream" fl>{props.scream}</Typography>
            </ScreamContainer>
            <BodyContainer {...props} >
                <Typography as="h3" type="body">{props.description}</Typography>
            </BodyContainer>
        </React.Fragment>
    )
}

TitleSection.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string
}

export default TitleSection;