import * as actionTypes from '../actions/actionTypes';

const initialState = {
  loading: false,
  config: {},
  errorApp: null,
  loadingOauthInfo: false,
  oauthInfo: null,
  oauthInfoError: null,
  loadingGoToPanel: false,
  loadingApp: false,
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.APP_INIT_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.APP_INIT_SUCCESS:
      return {
        ...state,
        loading: false,
        config: {
          ...state.config,
          ...action.config,
        },
      };
    case actionTypes.APP_INIT_ERROR:
      return {
        ...state,
        loading: false,
        errorApp: action.error,
      };
    case actionTypes.OAUTH_INFO_START:
      return {
        ...state,
        loadingOauthInfo: true,
      };
    case actionTypes.OAUTH_INFO_SUCCESS:
      return {
        ...state,
        loadingOauthInfo: false,
        oauthInfo: action.info,
      };
    case actionTypes.OAUTH_INFO_ERROR:
      return {
        ...state,
        loadingOauthInfo: false,
        oauthInfoError: action.error,
      };
    case actionTypes.GO_TO_PANEL_START:
      return {
        ...state,
        loadingGoToPanel: true,
      };
    case actionTypes.GO_TO_PANEL_SUCCESS:
      return {
        ...state,
        loadingGoToPanel: false,
      };
    case actionTypes.GO_TO_PANEL_ERROR:
      return {
        ...state,
        loadingGoToPanel: false,
      };
    case actionTypes.APP_LOADING_START:
      return {
        ...state,
        loadingApp: true,
      };
    case actionTypes.APP_LOADING_SUCCESS:
      return {
        ...state,
        loadingApp: false,
      };
    default: {
      return state;
    }
  }
};

export default appReducer;
