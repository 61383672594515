import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import NavigationItem from './NavigationItem/NavigationItem';
import { Text } from '../../Ui';
import { respondTo } from '../../../theme/mixin';
import { useTranslation } from 'react-i18next';
import Logout from '../../Logout/Logout';

const NavUl = styled.ul`
  list-style: none;

  ${respondTo.sm`
    display: flex;
    align-items: center;
    height: 100%;
    box-shadow: none;
	`}

  .only-mobile {
    display: block;
    border-top: 1px solid #dce4e8;
    padding: 15px 0;

    ${respondTo.sm`
      display: none;
    `}
  }
`;

const NavigationItems = props => {
  const { isLogged, multipromo, i18l, idGame, isOpen, logout } = props;

  const [t] = useTranslation();

  return (
    <NavUl isOpen={isOpen}>
      {multipromo ? (
        <NavigationItem link='/' active='true'>
          <Text>{i18l.nav.allthepromo ? i18l.nav.allthepromo : t('app.allthepromo')}</Text>
        </NavigationItem>
      ) : null}
      {isLogged && (
        <>
          <NavigationItem link='/' active='true'>
            <Text bold>{t('header.homepage')}</Text>
          </NavigationItem>
          {idGame && (
            <NavigationItem link={`/promo/game/${idGame}`} active='true'>
              <Text bold>Gioca e vinci</Text>
            </NavigationItem>
          )}
          {/* <NavigationItem link='/advantages' active='true'>
            <Text bold>{t('header.awards')}</Text>
          </NavigationItem> */}
          <NavigationItem link='/contact' active='true'>
            <Text bold>{t('header.contact')}</Text>
          </NavigationItem>
          <NavigationItem link='/user/profile' active='true'>
            <Text bold>{t('header.profile')}</Text>
          </NavigationItem>
          <div className='only-mobile' onClick={logout}>
            <Logout label={t('auth.logoutlabel')} />
          </div>
        </>
      )}
      {i18l.nav.menu_static_links
        ? i18l.nav.menu_static_links.map((item, i) => {
            return (
              <NavigationItem link={item.url} active='true' key={i}>
                <Text>{item.label}</Text>
              </NavigationItem>
            );
          })
        : null}
    </NavUl>
  );
};

NavigationItems.propTypes = {
  isLogged: PropTypes.string,
};
export default NavigationItems;
