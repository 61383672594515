import React from 'react';
import styled from 'styled-components';
import usernameIcon from '../../../../assets/images/username-icon.svg';
import passwordIcon from '../../../../assets/images/password-icon.svg';
import emailIcon from '../../../../assets/images/email-icon.svg';

const InputWrap = styled.div`
  position: relative;
`;

const InputField = styled.input`
  border: 2px solid
    ${props =>
      (!props.isvalid && props.istouched && props.firstSubmit) ||
      (!props.isvalid && props.firstSubmit)
        ? props.theme.input.error
        : props.theme.input.bg};
  color: ${props => props.theme.primary};
  padding: 0 15px;
  background-color: ${props => props.theme.input.bg};
  border-radius: 10px;
  width: ${props => (props.type === 'checkbox' ? '20px' : '100%')};
  height: 50px;
  font-size: 14px;
  font-weight: 700;
  outline: none;
  opacity: ${props => (props.readonly ? '0.3' : '1')};

  &::placeholder {
    color: #47545d59;
  }
`;

const InputIcon = styled.div`
  position: absolute;
  top: 13px;
  right: 10px;
`;

const Input = props => {
  return (
    <InputWrap>
      <InputField {...props} {...props.elementConfig} />

      {props.icon && (
        <>
          <InputIcon>
            <img
              src={
                props.icon === 'username'
                  ? usernameIcon
                  : props.icon === 'password'
                  ? passwordIcon
                  : props.icon === 'email'
                  ? emailIcon
                  : null
              }
              alt=''
            />
          </InputIcon>
        </>
      )}
    </InputWrap>
  );
};

export default Input;
