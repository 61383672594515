import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Loader from '../Loader/Loader';

const ButtonWrap = styled.button`
  border-radius: 50px;
  border: 4px solid
    ${props =>
      props.secondary ? props.theme.secondary : props.white ? '#fff' : props.theme.primary};
  display: inline-block;
  text-align: center;
  cursor: ${props => (props.loading || props.disabled ? 'default' : 'pointer')};
  outline: none;
  color: ${props =>
    props.secondary ? props.theme.secondary : props.white ? '#fff' : props.theme.primary};
  padding: 0 25px;
  background-color: transparent;
  transition: all 0.3s;
  height: 50px;
  font-size: 20px;
  font-weight: 700;
  text-transform: ${props => (props.upper ? 'uppercase' : 'none')};
  opacity: ${props => (props.loading || props.disabled ? '.5' : '1')};
  width: ${props => (props.fullwidth ? '100%' : 'auto')};
  line-height: 1;

  &:hover {
    color: ${props => (props.white ? props.theme.primary : '#fff')};
    background-color: ${props =>
      props.secondary ? props.theme.secondary : props.white ? '#fff' : props.theme.primary};
  }
`;

const Button = props => {
  return (
    <ButtonWrap {...props}>
      {props.loading ? (
        <Loader show={true} inBtn white={props.white ? true : false} />
      ) : (
        props.children
      )}
    </ButtonWrap>
  );
};

Button.defaultProps = {
  upper: true,
};

Button.propTypes = {
  disabled: PropTypes.bool,
  small: PropTypes.bool,
  fullwidth: PropTypes.bool,
  secondary: PropTypes.bool,
  upper: PropTypes.bool,
  loading: PropTypes.bool,
  white: PropTypes.bool,
};

export default Button;
