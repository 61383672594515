import React from 'react';
import { respondTo } from '../../theme/mixin';
import { Hamburger, SwitchLang } from '../Ui';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import LoginBtn from '../LoginBtn';
import Logout from '../Logout';
import styled from 'styled-components';
import { Close } from '@styled-icons/material';
import { Menu } from 'styled-icons/boxicons-regular';

const MenuLateralContainer = styled.div`
  display: flex;
  align-items: center;
`;

const MenuContainerDesk = styled.div`
  display: none;

  ${respondTo.sm`
		display: block;
  `}
`;

const MenuContainerMobile = styled.div`
  display: flex;

  ${respondTo.sm`
		display: none;
	`}
`;

const MenuLateral = props => {
  const { toggleMenu, isMenuOpen, logout, isLogged, ownLogin, oauths, oauthInfo, bootApp, langs } =
    props;

  const [t] = useTranslation();

  return (
    <MenuLateralContainer>
      <MenuContainerDesk>
        {isLogged ? (
          <Logout onClick={logout} label={t('auth.logoutlabel')} />
        ) : (
          <LoginBtn
            label={t('auth.signinlabel')}
            ownlogin={ownLogin}
            oauths={oauths}
            oauthinfo={oauthInfo}
          />
        )}
        {langs.length > 1 ? <SwitchLang bootApp={bootApp} langs={langs} /> : null}
      </MenuContainerDesk>
      <MenuContainerMobile onClick={toggleMenu}>
        {isMenuOpen ? <Close size={45} /> : <Menu size={45} />}
      </MenuContainerMobile>
    </MenuLateralContainer>
  );
};

MenuLateral.propTypes = {
  toggleSidebar: PropTypes.func,
  logout: PropTypes.func,
  isLogged: PropTypes.string,
  ownLogin: PropTypes.number,
  oauths: PropTypes.array,
  oauthInfo: PropTypes.object,
  appInit: PropTypes.func,
  langs: PropTypes.array,
  oauth_profile_url: PropTypes.string,
};

export default MenuLateral;
