import moment from 'moment';

export const signinform = (ref, inModal) => {
  return {
    email: {
      elementType: 'input',
      elementConfig: {
        type: 'email',
        name: 'email',
        id: 'email',
        placeholder: 'Username',
        ref,
      },
      icon: 'username',
      label: '',
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
      inModal,
    },
    password: {
      elementType: 'input',
      elementConfig: {
        type: 'password',
        name: 'password',
        id: 'password',
        placeholder: 'Password',
      },
      icon: 'password',
      label: 'Password',
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
      inModal,
    },
  };
};

export const registrationform = (
  email,
  firstname,
  lastname,
  username,
  company,
  company_role,
  psw,
  pswrepeat,
  dateofbirth,
  sex,
  male,
  female,
  pswerror,
  pswconfirmerror,
  ref,
  gdpr,
  gdprAccept,
  gdprReject,
) => {
  return {
    email: {
      elementType: 'input',
      elementConfig: {
        type: 'email',
        name: 'email',
        id: 'email',
        placeholder: 'Email',
      },
      icon: 'email',
      label: `${email}`,
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    password: {
      elementType: 'input',
      elementConfig: {
        type: 'password',
        name: 'password',
        id: 'password',
        placeholder: 'Password',
      },
      icon: 'password',
      label: `${psw}`,
      value: '',
      validation: {
        toMatch: true,
        isPassword: true,
        required: true,
        touched: false,
      },
      errorMessage: `${pswerror}`,
      valid: false,
    },
    repeatpassword: {
      elementType: 'input',
      elementConfig: {
        type: 'password',
        name: 'repeatpassword',
        id: 'repeatpassword',
        placeholder: 'Conferma password',
      },
      icon: 'password',
      label: `${pswrepeat}`,
      value: '',
      validation: {
        isPassword: true,
        isConfirmPassword: true,
        required: true,
        touched: false,
      },
      errorMessage: `${pswconfirmerror}`,
      valid: false,
    },
    firstname: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'firstname',
        id: 'firstname',
        placeholder: 'Nome',
      },
      label: `${firstname}`,
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    lastname: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'lastname',
        id: 'lastname',
        placeholder: 'Cognome',
      },
      label: `${lastname}`,
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    username: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'username',
        id: 'username',
        placeholder: '',
      },
      label: `${username}`,
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    company: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'company',
        id: 'company',
        placeholder: `${company}`,
      },
      label: `${company}`,
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    company_role: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'company_role',
        id: 'company_role',
        placeholder: `${company_role}`,
      },
      label: `${company_role}`,
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    address: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'address',
        id: 'address',
        placeholder: 'Indirizzo',
      },
      label: '',
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    phone: {
      elementType: 'input',
      elementConfig: {
        type: 'number',
        name: 'phone',
        id: 'phone',
        placeholder: 'Cellulare',
      },
      label: '',
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    dob: {
      elementType: 'datepicker',
      elementConfig: {
        placeholder: 'Data di nascita',
        name: 'dob',
      },
      label: `${dateofbirth}`,
      value: '',
      valuetosend: '',
      validation: {
        required: true,
        touched: false,
        maxDate: 'today',
      },
      valid: false,
    },
    gender: {
      elementType: 'select',
      elementConfig: {
        options: [
          { value: 'm', displayValue: `${male}` },
          { value: 'f', displayValue: `${female}` },
        ],
        placeholder: '',
        name: 'gender',
      },
      label: `${sex}`,
      value: ``,
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    district: {
      elementType: 'select',
      isDistrict: true,
      elementConfig: {
        options: [
          {
            value: '',
            displayValue: 'Seleziona provincia',
            disabled: true,
          },
        ],
        placeholder: 'Provincia',
        name: 'district',
      },
      label: 'Provincia',
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    city: {
      elementType: 'select',
      isCity: true,
      elementConfig: {
        options: [
          {
            value: '',
            displayValue: 'Seleziona città',
            disabled: true,
          },
        ],
        placeholder: 'Città',
        name: 'city',
      },
      label: 'Città',
      value: ``,
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    zipcode: {
      elementType: 'input',
      isZipcode: true,
      elementConfig: {
        type: 'text',
        name: 'zipcode',
        id: 'zipcode',
        placeholder: 'CAP',
      },
      label: `zipcode`,
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    regulation: {
      elementType: 'inputcheckbox',
      elementConfig: {
        type: 'checkbox',
        placeholder: '',
        name: 'regulation',
        id: 'regulation',
      },
      label: '<p>Dichiaro di aver letto e accettato il <a href="">regolamento</a></p>',
      value: 0,
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    gdpr_privacy: {
      elementType: 'inputcheckbox',
      elementConfig: {
        type: 'checkbox',
        placeholder: '',
        name: 'gdpr_privacy',
        id: 'gdpr_privacy',
      },
      label:
        '<p>Dichiaro di aver letto e accettato l\'<a href="https://plugandplay.nextgroup.eu/pdf/privacy_policy.pdf" target="_blank">informativa sulla privacy</a></p>',
      value: 0,
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    gdpr_marketing: {
      elementType: 'inputcheckbox',
      elementConfig: {
        type: 'checkbox',
        placeholder: '',
        name: 'gdpr_marketing',
        id: 'gdpr_marketing',
      },
      label:
        '<p>Autorizzo il trattamento dei miei dati personali per finalità di marketing diretto</p>',
      value: 0,
      validation: {
        required: false,
        touched: false,
      },
      valid: true,
    },
    /* gdpr_marketing: {
      elementType: 'inputradio',
      elementConfig: {
        type: 'radio',
        placeholder: '',
        name: 'gdpr_marketing',
        id: 'gdpr_marketing',
      },
      label: ``,
      value: null,
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
      inputs: [
        {
          id: 'gdpr_marketing_accept',
          label: `${gdprAccept}`,
          value: 1,
        },
        {
          id: 'gdpr_marketing_reject',
          label: `${gdprReject}`,
          value: 0,
        },
      ],
    }, */
    gdpr_profiling: {
      elementType: 'inputcheckbox',
      elementConfig: {
        type: 'checkbox',
        placeholder: '',
        name: 'gdpr_profiling',
        id: 'gdpr_profiling',
      },
      label:
        '<p>Autorizzo il trattamento dei miei dati personali per finalità di profilazione</p>',
      value: 0,
      validation: {
        required: false,
        touched: false,
      },
      valid: true,
    },
  };
};

export const recoveryPasswordForm = (emailLabel, ref) => {
  return {
    email: {
      elementType: 'input',
      elementConfig: {
        type: 'email',
        name: 'email',
        id: 'email',
        placeholder: 'Email',
        ref,
      },
      icon: 'email',
      label: `${emailLabel}`,
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
  };
};

export const changePasswordForm = (
  newPswLabel,
  confirmNewPsw,
  pswerror,
  pswconfirmerror,
  pswwrong,
  ref,
) => {
  return {
    password: {
      elementType: 'input',
      elementConfig: {
        type: 'password',
        name: 'password',
        id: 'password',
        placeholder: 'Password',
        ref,
      },
      value: '',
      validation: {
        isPassword: true,
        required: true,
        touched: false,
      },
      valid: false,
    },
    confirmpassword: {
      elementType: 'input',
      elementConfig: {
        type: 'password',
        name: 'confirmpassword',
        id: 'confirmpassword',
        placeholder: `${newPswLabel}`,
        ref,
      },
      value: '',
      validation: {
        toMatch: true,
        isPassword: true,
        required: true,
        touched: false,
      },
      errorMessage: `${pswerror}`,
      valid: false,
    },
    repeatpassword: {
      elementType: 'input',
      elementConfig: {
        type: 'password',
        name: 'repeatpassword',
        id: 'repeatpassword',
        placeholder: `${confirmNewPsw}`,
      },
      value: '',
      validation: {
        isPassword: true,
        isConfirmPassword: true,
        required: true,
        touched: false,
      },
      errorMessage: `${pswconfirmerror}`,
      valid: false,
    },
  };
};

export const resetPasswordForm = (newPswLabel, confirmNewPsw, pswerror, pswconfirmerror, ref) => {
  return {
    password: {
      elementType: 'input',
      elementConfig: {
        type: 'password',
        name: 'password',
        id: 'password',
        placeholder: 'Nuova password',
        ref,
      },
      label: `${newPswLabel}`,
      value: '',
      validation: {
        toMatch: true,
        isPassword: true,
        required: true,
        touched: false,
      },
      errorMessage: `${pswerror}`,
      valid: false,
    },
    confirmpassword: {
      elementType: 'input',
      elementConfig: {
        type: 'password',
        name: 'confirmpassword',
        id: 'confirmpassword',
        placeholder: 'Conferma nuova password',
      },
      label: `${confirmNewPsw}`,
      value: '',
      validation: {
        isPassword: true,
        isConfirmPassword: true,
        required: true,
        touched: false,
      },
      errorMessage: `${pswconfirmerror}`,
      valid: false,
    },
  };
};

export const uploadReceiptForm = (dateLabel, numberLabel, amountLabel, timeLabel, ref) => {
  return {
    file: {
      elementType: 'inputfile',
      elementConfig: {
        type: 'file',
        accept: 'image/*',
        placeholder: 'Carica',
        name: 'file',
        id: 'file',
      },
      label: '',
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
      inModal: true,
      ref,
    },
    /* fileextra: {
      elementType: 'inputfile',
      elementConfig: {
        type: 'file',
        accept: 'image/*',
        placeholder: 'Carica il retro dello scontrino',
        name: 'fileextra',
        id: 'fileextra',
      },
      label: '',
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
      inModal: true,
    }, */
    date: {
      elementType: 'datepicker',
      elementConfig: {
        placeholder: `${dateLabel}`,
        name: 'date',
      },
      label: '',
      value: '',
      valuetosend: '',
      validation: {
        required: true,
        touched: false,
        maxDate: 'today', //mm-dd-yyyy or today
      },
      valid: false,
    },
    time: {
      elementType: 'inputtime',
      elementConfig: {
        type: 'time',
        name: 'time',
        id: 'time',
        placeholder: `${timeLabel}`,
      },
      label: '',
      value: '',
      valuetosend: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    number: {
      elementType: 'input',
      elementConfig: {
        type: 'number',
        name: 'number',
        id: 'number',
        placeholder: `${numberLabel}`,
      },
      label: '',
      value: '',
      min: '0',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    amount: {
      elementType: 'price',
      elementConfig: {
        type: 'number',
        name: 'amount',
        id: 'amount',
        placeholder: `${amountLabel}`,
      },
      label: '',
      value: '',
      min: '0',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
  };
};

export const contactForm = () => {
  return {
    topic: {
      elementType: 'select',
      elementConfig: {
        options: [
          { value: '', displayValue: `Seleziona argomento`, disabled: true },
          { value: 'Premio', displayValue: 'Premio' },
          { value: 'Gioco', displayValue: 'Gioco' },
          { value: 'Altro', displayValue: 'Altro' },
        ],
        placeholder: `Seleziona argomento`,
        name: 'topic',
      },
      label: '',
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    message: {
      elementType: 'textarea',
      elementConfig: {
        name: 'message',
        id: 'message',
        placeholder: 'Il tuo messaggio',
      },
      label: '',
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
  };
};

export const profileform = user => {
  return {
    firstname: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'firstname',
        id: 'firstname',
        placeholder: 'Nome',
      },
      label: '',
      value: user.firstname,
      validation: {
        required: true,
        touched: false,
      },
      valid: user.firstname ? true : false,
    },
    lastname: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'lastname',
        id: 'lastname',
        placeholder: `Cognome`,
      },
      label: '',
      value: user.lastname,
      validation: {
        required: true,
        touched: false,
      },
      valid: user.lastname ? true : false,
    },
    email: {
      elementType: 'input',
      elementConfig: {
        type: 'email',
        name: 'email',
        id: 'email',
        placeholder: 'Email',
      },
      label: '',
      value: user.email,
      validation: {
        required: true,
        touched: false,
      },
      valid: user.email ? true : false,
    },
    phone: {
      elementType: 'input',
      elementConfig: {
        type: 'number',
        name: 'phone',
        id: 'phone',
        placeholder: 'Cellulare',
      },
      label: '',
      value: user.phone,
      validation: {
        required: true,
        touched: false,
      },
      valid: user.phone ? true : false,
    },
    company: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'company',
        id: 'company',
        placeholder: 'Azienda',
      },
      label: '',
      value: user.company,
      validation: {
        required: true,
        touched: false,
      },
      valid: user.company ? true : false,
    },
    company_role: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'company_role',
        id: 'company_role',
        placeholder: 'Ruolo',
      },
      label: '',
      value: user.company_role || '',
      validation: {
        required: true,
        touched: false,
      },
      valid: user.company_role ? true : false,
    },
    dob: {
      elementType: 'datepicker',
      elementConfig: {
        placeholder: 'Data di nascita',
        name: 'dob',
      },
      lang: 'it',
      label: '',
      value: moment(user.dob).format('DD/MM/YYYY') || '',
      valuetosend: moment(user.dob).format('L') || '',
      validation: {
        required: true,
        touched: false,
      },
      valid: user.dob ? true : false,
    },
    address: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'address',
        id: 'address',
        placeholder: 'Indirizzo',
      },
      label: '',
      value: user.address,
      validation: {
        required: true,
        touched: false,
      },
      valid: user.address ? true : false,
    },
    district: {
      elementType: 'select',
      isDistrict: true,
      elementConfig: {
        options: [{ value: '', displayValue: 'Seleziona provincia', disabled: true }],
        placeholder: 'Provincia',
        name: 'district',
      },
      label: '',
      value: user.district || '',
      validation: {
        required: true,
        touched: false,
      },
      valid: user.district ? true : false,
    },
    city: {
      elementType: 'select',
      isCity: true,
      elementConfig: {
        options: [
          {
            value: user.city || '',
            displayValue: user.city || 'Seleziona città',
            disabled: true,
          },
        ],
        name: 'city',
      },
      label: '',
      value: user.city || '',
      validation: {
        required: true,
        touched: false,
      },
      valid: user.city ? true : false,
    },
    zipcode: {
      elementType: 'input',
      isZipcode: true,
      elementConfig: {
        type: 'text',
        name: 'zipcode',
        id: 'zipcode',
        placeholder: 'CAP',
      },
      label: '',
      value: user.zipcode || '',
      validation: {
        required: true,
        touched: false,
      },
      valid: user.zipcode ? true : false,
    },
  };
};
