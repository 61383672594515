import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button } from '../../components/Ui';
import { useParams, Link } from 'react-router-dom';
import { respondTo } from '../../theme/mixin';
import customaxios from '../../config/axios-refresh-token';
import { useDispatch, useSelector } from 'react-redux';
import * as actionCreators from '../../store/actions';
import ErrorInModal from '../../components/ErrorInModal';
import { Modal, Flex, Col, Box, Text } from '../../components/Ui';
import Fade from '../../components/Fade/Fade';
import AdvantageBox from '../../components/AdvantageBox/AdvantageBox';

const SingleAdvantage = props => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const [partner, setPartner] = useState();
  const [modalError, setModalError] = useState(false);
  const [hasCoupon, setHasCoupon] = useState(null);

  const partners = useSelector(state => state.partners.partnerList);
  const vouchers = useSelector(state => state.user.user.vouchers);

  useEffect(() => {
    if (id && partners) {
      const newPartner = partners.find(partner => partner.id === Number(id));

      setPartner(newPartner);
    }
  }, [partners, id]);

  useEffect(() => {
    if (partner && vouchers) {
      const existCoupon = vouchers.find(voucher => voucher.partner_id === partner.id);

      if (existCoupon) {
        setHasCoupon(existCoupon);
      }
    }
  }, [partner, vouchers]);

  const requestCouponHandler = () => {
    let endpoint = `partners/${partner.id}/request/`;
    customaxios
      .post(endpoint)
      .then(result => {
        dispatch(actionCreators.getUserInfo());
      })
      .catch(error => {
        setModalError(error.response.data.error);
      });
  };

  const closeModalError = () => {
    setModalError(null);
  };

  return (
    <Fade>
      <Flex row={15}>
        {partner && (
          <>
            <Col width={33.333}>
              <AdvantageBox partner={partner} />
            </Col>
            <Col width={66.666}>
              <Box className='mb-30'>
                <Text primary size={34} className='mb-20'>
                  {partner.subtitle}
                </Text>
                <Text style={{ marginBottom: 50 }}>{partner.description}</Text>
                <Flex justify='space-between'>
                  <Link to='/advantages'>
                    <Button secondary>Indietro</Button>
                  </Link>
                  {!hasCoupon && <Button onClick={() => requestCouponHandler()}>Richiedi</Button>}
                </Flex>
              </Box>

              {hasCoupon && (
                <Box primary>
                  <Text white size={34} className='mb-20'>
                    Ecco il tuo coupon
                  </Text>
                  <Flex align='center' className='mb-30'>
                    <Text white className='md_mb-20'>
                      {hasCoupon.partner.subtitle}
                    </Text>
                    <CouponCode>
                      <Text primary size={24} align='center'>
                        {hasCoupon.code}
                      </Text>
                    </CouponCode>
                  </Flex>

                  <Flex justify='flex-end' align='center'>
                    <Text
                      white
                      bold
                      upper
                      style={{ opacity: 0.6, marginRight: 20, cursor: 'pointer' }}
                      onClick={() => {
                        navigator.clipboard.writeText(hasCoupon.code);
                      }}
                      className='md_mb-10'
                    >
                      Copia il codice
                    </Text>

                    <Link
                      to={{
                        pathname: partner.url,
                      }}
                      target='_blank'
                    >
                      <Button style={{ color: '#fff', borderColor: '#fff' }}>
                        Vai al sito partner
                      </Button>
                    </Link>
                  </Flex>

                  <CouponTerms>
                    <Text bold upper primary size={18} className='mb-10'>
                      Termini e condizioni
                    </Text>
                    <Text>{partner.howto}</Text>
                  </CouponTerms>
                </Box>
              )}
            </Col>
          </>
        )}
      </Flex>
      <Modal show={modalError} close={closeModalError}>
        <ErrorInModal error={modalError} />
      </Modal>
    </Fade>
  );
};

const CouponCode = styled.div`
  flex: 0 0 100%;
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  margin-left: 0;

  ${respondTo.md`
    flex: 0 0 455px;
    margin-left: 15px;
  `}
`;

const CouponTerms = styled.div`
  background-color: #fff;
  padding: 15px;
  margin: 15px -15px -25px -15px;
`;

export default SingleAdvantage;
