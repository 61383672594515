import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { respondTo } from '../../../theme/mixin';

const Flex = props => {
  return <FlexWrap {...props}>{props.children}</FlexWrap>;
};

const FlexWrap = styled.div`
  display: flex;
  flex-direction: ${props => props.direction};
  align-items: ${props => props.align};
  justify-content: ${props => props.justify};
  flex-wrap: wrap;
  margin: 0 -${props => props.row}px;

  ${respondTo.sm`
    flex-wrap: ${props => props.wrap};
  `};
`;

Flex.defaultProps = {
  align: 'flex-start',
  justify: 'flex-start',
  direction: 'row',
  wrap: 'nowrap',
  row: 0,
};

Flex.propsTypes = {
  direction: PropTypes.string,
  align: PropTypes.string,
  justify: PropTypes.string,
  wrap: PropTypes.string,
  row: PropTypes.number,
};

export default Flex;
